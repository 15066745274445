<template>
  <div
    id="mydiv"
    style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: hidden; z-index: 99;"
  />
</template>

<script>
// import { fetchExternalApi } from '@jitsi/web-sdk';

export default {
  components: {

  },
  // created: () => {
  //   window.addEventListener('load', this.loadEvent)
  // },
  // destroyed: () => {
  //   window.removeEventListener('load', this.loadEvent)
  // },
  // methods: {
  //   generateRoomName() {
  //     const allowedCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  //     const roomNameLength = 10
  //     let roomName = ''

  //     for (let i = 0; i < roomNameLength; i++) {
  //       const randomNb = Math.floor(Math.random() * allowedCharacters.length)
  //       roomName += allowedCharacters[randomNb]
  //     }

  //     return roomName
  //   },
  //   loadEvent() {
  //     const params = new URLSearchParams(window.location.search)
  //     const args = Object.fromEntries(params.entries())
  //     const clanmeetingExt = document.createElement('script')
  //     clanmeetingExt.setAttribute('src', `https://${args.domain}/external_api.js`)
  //     document.head.appendChild(clanmeetingExt)

  //     if (!args.consumerId) {
  //       window.alert('Please append your brand/app name as consumerId to the URL.')
  //       return
  //     }
  //     if (!args.domain || !args.displayName) {
  //       window.alert('Missing or invalid mandatory parameter.')
  //       return
  //     }
  //     const domain = `${args.domain}/${args.consumerId}`

  //     const options = {
  //       roomName: args.roomName ?? this.generateRoomName(),
  //       parentNode: document.querySelector('#mydiv'),
  //       configOverwrite: {
  //         startWithAudioMuted: true,
  //         startWithVideoMuted: true,
  //         prejoinPageEnabled: false,
  //         disableDeepLinking: true,
  //         toolbarButtons: [
  //           'microphone', 'camera', 'closedcaptions', 'fullscreen', 'hangup', 'chat',
  //           'recording', 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
  //           'videoquality', 'filmstrip', 'invite', 'stats', 'shortcuts',
  //           'select-background', 'mute-everyone', 'security', 'toggle-camera',
  //         ],
  //       },
  //       userInfo: {
  //         displayName: args.displayName ?? 'Participant',
  //       },
  //       jwt: args.jwt ?? '',
  //     }

  //     let api = ""
  //     fetchExternalApi().then(JitsiMeetExternalApi => {
  //       api = new JitsiMeetExternalApi(domain, options);
  //     });
  //     // const api = new JitsiMeetExternalAPI(domain, options)
  //     const videoConferenceLeftHandler = () => {
  //       window.location.href = args.onCloseRedirectUrl ?? `https://${args.domain}/static/close2.html`
  //     }
  //     api.on('videoConferenceLeft', videoConferenceLeftHandler)
  //     // Dispose API
  //     window.addEventListener('unload', () => {
  //       api.removeAllListeners(['videoConferenceLeft'])
  //       api.dispose()
  //     })
  //   },
  // },
}
</script>
